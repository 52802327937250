import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const TypesOfCampaigns = ({ location }) => {
  const title = "Can you organize a campaign other than Instant Win?"
  const list = [
    { title: "Frequently Asked Questions", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="PARKLoT allows you to choose how to participate in the campaign → follow & retweet, hashtag, and post receipts, how to draw → manual drawing, random drawing, scratch-off, or game, and how to give away → merchandise, digital gift, or digital coupon."
          pagepath={location.pathname}
        />
        <Breadcrumb title="Frequently Asked Questions" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              What kind of campaign can I organize?
            </h1>
            <p className="qa-page__answer">
              Follow &amp; Retweet Campaign", "Hashtag Campaign" and "Receipt
              Submission Campaign" can be held.
              <br />
              Drawing methods and gifts can be selected according to the target
              audience and the products and services handled.
            </p>
            <div className="qa-page__detail-container">
              <p className="qa-page__text">
                The PARKLoT campaign will follow the following flow:
                <br />
                (1) Official corporate Twitter account tweets campaign tweets
                <br />
                (2) Users participate in the campaign
                <br />
                (3) Lottery
                <br />
                (4) Gaining
                <br />
              </p>
              <p className="qa-page__text">
                There are multiple methods for each of the "(2) User
                participation in campaign," "(3) Lottery," and "(4) Winning
                prizes" in this flow, which can be selected according to the
                purpose of the campaign and the products or services to be
                offered.
              </p>
              <p className="qa-page__text">
                Here is an overview of each of these options and their fees.
              </p>
              <h2 className="qa-page__h2">
                Regarding the options available in "(2) User Participation in
                the Campaign".
              </h2>
              <p className="qa-page__text">
                There are three ways to participate.
                <br />
                (1) Follow &amp; Retweet
                <br />
                (2) Hashtag
                <br />
                (3) Receipt Submission
                <br />
              </p>
              <h3 className="qa-page__h3">(1) Follow &amp; Retweet</h3>
              <p className="qa-page__text">
                Follow the company's official account and retweet the campaign
                tweets to participate in the campaign.
              </p>
              <p className="qa-page__text">
                Included in the fixed monthly plan.
              </p>
              <h3 className="qa-page__h3">(2) Hashtag</h3>
              <p className="qa-page__text">
                Participate in the campaign by tweeting tweets containing
                specific hashtags.
              </p>
              <p className="qa-page__text">
                Optional fees are required in addition to the fixed monthly
                plan.
              </p>
              <h3 className="qa-page__h3">(3) Receipt Submission</h3>
              <p className="qa-page__text">
                Send receipts of your product purchases via DM to participate in
                the campaign.
              </p>
              <p className="qa-page__text">
                Optional fees are required in addition to the fixed monthly
                plan.
              </p>
              <h2 className="qa-page__h2">
                About the options available in "(3) Lottery."
              </h2>
              <p className="qa-page__text">
                There are two drawing methods: "after-period drawing" and
                "real-time drawing.
              </p>
              <p className="qa-page__text">
                There are two types of "post-period lotteries": "random
                lotteries" and "manual lotteries.
                <br />
                In the case of real-time drawings, there are three types of
                drawings: "random drawings," "scratch-offs," and "games.
                <br />
              </p>
              <h3 className="qa-page__h3">
                (1) Lottery after the end of the period
              </h3>
              <p className="qa-page__text">
                The winners will be drawn together at the end of the campaign
                period and participants will be notified of the winning results.
              </p>
              <p className="qa-page__text">
                Manual Drawing: Manually select winners from a list of
                applicants and manually send DMs to the winners.
              </p>
              <p className="qa-page__text">
                Random Drawing: We will randomly select winners from a list of
                applicants and manually send DMs to the winners.
              </p>
              <h3 className="qa-page__h3">(2) Real-time lottery</h3>
              <p className="qa-page__text">
                Upon entry into the campaign, participants are immediately
                notified of the results of their winners.
              </p>
              <p className="qa-page__text">
                In the case of the real-time lottery, you can choose from three
                different lottery methods.
              </p>
              <h4 className="qa-page__h4">Random Drawings</h4>
              <p className="qa-page__text">
                PARKLoT's system will automatically draw lots according to the
                pre-set winning percentage, and send a DM containing the URL of
                the confirmation screen for the lottery results to the
                participants.
              </p>
              <p className="qa-page__text">
                Participants who receive the DM will be redirected to the
                lottery results confirmation screen to confirm the results.
              </p>
              <p className="qa-page__text">
                Included in the fixed monthly plan.
              </p>
              <h4 className="qa-page__h4">Scratch</h4>
              <p className="qa-page__text">
                A DM containing the scratch card URL will be sent to
                participants.
              </p>
              <p className="qa-page__text">
                When a participant who receives the DM clicks on the URL, a
                scratch card is displayed and the card is shaved to confirm the
                winning result.
              </p>
              <p className="qa-page__text">
                Optional fees are required in addition to the fixed monthly
                plan.
              </p>
              <h4 className="qa-page__h4">Game</h4>
              <p className="qa-page__text">
                A DM containing the URL to join the game will be sent to
                participants.
              </p>
              <p className="qa-page__text">
                Participants who receive the DM will click on the URL and
                participate in the game. After the game is conducted, the winner
                will be confirmed.
              </p>
              <p className="qa-page__text">
                Optional fees are required in addition to the fixed monthly
                plan.
              </p>
              <h2 className="qa-page__h2">
                (3) About the choices available in the gift
              </h2>
              <p className="qa-page__text">
                Gifts include "merchandise," "digital gift codes," and "digital
                coupons.
              </p>
              <h3 className="qa-page__h3">Goods</h3>
              <p className="qa-page__text">
                You can present items that need to be delivered.
              </p>
              <p className="qa-page__text">
                Winning participants will register their addresses on a
                dedicated page provided by PARKLoT.
              </p>
              <p className="qa-page__text">
                The organizer will deliver the goods to the registered address.
              </p>
              <p className="qa-page__text">
                This can be done within a fixed monthly plan.
              </p>
              <h3 className="qa-page__h3">Digital Gift Codes</h3>
              <p className="qa-page__text">
                You can give away digital gift codes such as Amazon gift
                certificates.
              </p>
              <p className="qa-page__text">
                If you wish to send a gift code manually via Twitter DM from the
                winners' list screen, you can do so within your monthly
                subscription plan.
              </p>
              <p className="qa-page__text">
                If you wish to automatically send gift codes to winners, an
                optional fee is required in addition to the monthly subscription
                plan.
              </p>
              <h3 className="qa-page__h3">Digital Coupons</h3>
              <p className="qa-page__text">
                You can give away unique coupons that can be used at physical
                stores and online shops.
              </p>
              <p className="qa-page__text">
                Coupons can be selected from "Cancelable Coupons", "Timed
                Coupons" and "Unlimited Coupons for the duration of the coupon".
              </p>
              <p className="qa-page__text">
                The "Cancelable Coupon" allows staff at the store to tap a
                button on the screen to confirm use of the coupon, thereby
                preventing multiple uses of the coupon.
                <br />
                The "timed coupon" is automatically used after a certain amount
                of time has elapsed from the time it is displayed, eliminating
                the need for store staff to use the coupon.
                <br />
                The "Infinite Coupon for the duration" can be used any number of
                times during the specified period, thus encouraging continued
                use.
              </p>
              <p className="qa-page__text">
                This can be done within a fixed monthly plan.
              </p>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default TypesOfCampaigns
